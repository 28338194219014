<app-jumbotron></app-jumbotron>
<section class="bg-black">
  <div class="flex justify-center">
    <span class="f-5-ns f2 white fjalla pa3 mt5-ns mt3 pt4-ns pb5-ns">Concise Tech Blogs.</span>
  </div>
  <div class="flex flex-wrap justify-center pa1-ns pa3">
    <div class="indexbox flex cpuside">
      <a routerLink="/cpu" routerLinkActive="active" class="indexdirect f-6-ns f1 white link fjalla">
        CPU's
      </a>
    </div>
    <div class="indexbox flex gpuside">
      <a routerLink="/gpu" routerLinkActive="active" class="indexdirect f-6-ns f1 white link fjalla">
        GPU's
      </a>
    </div>
  </div>
  <div class="flex justify-center">
    <span class="f-5-ns f2 white fjalla pa3 mt5-ns mt3 pt4-ns pb5-ns"></span>
  </div>
</section>
