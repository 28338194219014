<main class="bg-black">
  <div class="pa5">
    <!-- Spacer -->
  </div>
  <div class="pa2 flex justify-center">
    <span class="white fjalla f-5-ns f1">Pick Your Poison.</span>
  </div>
  <div class="flex flex-wrap justify-center">
    <div class="pa1">
      <a href="gpu#Nvidia-Section"
         class="f1 w5 white link fjalla nvidia pa4 flex justify-center">
        Nvidia
      </a>
    </div>
    <div class="pa1">
      <a href="gpu#Amd-Section"
         class="f1 w5 white link fjalla AMD pa4 flex justify-center ">
        AMD
      </a>
    </div>
  </div>
  <div class="pa5-ns pa4">
    <!-- Spacer -->
  </div>
  <div class="flex flex-wrap justify-center white">
    <span class="f2 fjalla pa1 pl3 pr3">Or Search for GPU</span>
    <input class="ba bg-black white pl4 ma2 ml3 pr4 mr3 pt1-ns pt2-m pt3 pb1-ns pb2-m pb3 ma1-ns" list="gpus" name="gpulist" id="gpulist">
    <datalist id="gpus">
      <option *ngFor="let g of gpublogs" [value]="g.id+':'+g.productname"></option>
    </datalist>
    <button (click)="navigate()"
            class="ba fjalla bg-black white hover-black hover-bg-white pl4 ma2 ml3 pr4 mr3 pt1-ns pt2-m pt3 pb1-ns pb2-m pb3 ma1-ns">
      GO
    </button>
  </div>
  <div class="pa5-ns pa4">
    <!-- Spacer -->
  </div>


  <!-- Intel Section-->
  <div id="Nvidia-Section" style="background-color: green">
    <div class="pt5 pb5 w-100">
      <div class="flex flex-wrap w-100 pl5-ns pr5-ns justify-center">
        <div *ngFor="let gpublog of gpublogs">
          <div class="w5 h5 cover flex ma3 bg-center" [ngStyle]="{'background-image': 'url(' + gpublog.imgurl + ')'}" *ngIf="gpublog.productbrand == 'Nvidia'">
            <a class="f1 white link fjalla h-100 w-100 bg-black-70 hover-bg-black-90 flex justify-center items-center"
               [routerLink]="['/gpu', gpublog.id]">
              {{ gpublog.shortname}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- AMD Section-->
  <div id="Amd-Section" style="background-color: firebrick">
    <div class="pt5 pb5 w-100">
      <div class="flex flex-wrap w-100 pl5-ns pr5-ns justify-center">
        <div *ngFor="let gpublog of gpublogs">
          <div class="w5 h5 cover flex ma3 bg-center" [ngStyle]="{'background-image': 'url(' + gpublog.imgurl + ')'}" *ngIf="gpublog.productbrand == 'AMD'">
            <a
              class="f1 white link fjalla h-100 w-100 bg-black-70 hover-bg-black-90 flex justify-center items-center"
              [routerLink]="['/gpu', gpublog.id]">
              {{gpublog.shortname}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>


</main>
