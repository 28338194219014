<nav class="dt dt--fixed w-100 border-box bg-black pa3 ph5-ns bb b--white-10">
  <!--  LEFT PADDING TO CENTER LOGO  WHEN COLLAPSED -->
  <div class="dtc dn-ns w-third"></div>

  <!--  LOGO  -->
  <a class="dtc v-mid white link dim w-third w-25-ns tc tl-ns mb2 mb0-ns" href="#" title="Home">
    <img src="https://tachyons.io/img/logo.jpg" class="dib w2 h2 br-100" alt="Site Name">
  </a>

  <!-- LINKS / MENU  -->
  <div id="menu" class="static-ns bg-black-70 absolute mt5 mt0-ns left-100 db dtc-ns v-mid w-50 w-100-ns w-75-ns h-80 tr">
    <a class="link dim white pa4 pa1-ns pa1-m tr tl-ns f3 f5-ns db dib-ns mr3 mr4-ns fjalla" [routerLink]="['/cpu']" title="CPU's">CPU's</a>
    <a class="link dim white pa4 pa1-ns pa1-m tr tl-ns f3 f5-ns db dib-ns mr3 mr0-ns fjalla" [routerLink]="['/gpu']" title="GPU's">GPU's</a>
  </div>

  <!--  EXPAND MENU BUTTON  -->
  <a href="#" id="menu-btn" class="fjalla dtc tr v-mid dn-ns w-third pr2 p0-ns link dim white">
    Menu
  </a>
</nav>
